<template>
  <div class="mb-7">
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      @indexed-field-value-changed="checkUpdate(...arguments)"
      :headerTitle="false"
      :addable="true"
      :rerunsObject="rerunsObject"
      addButtTitle="Add Alternative Network"
    ></lineFieldsArray>
  </div>
</template>
<script>
  import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
  import requests from '@/js/requests';

  export default {
    components: { lineFieldsArray },
    data() {
      return { operatorList: [], rerunsObject: {} };
    },
    props: ["field", "fieldAttributes", "result","value"],

    computed: {
      fields() {
        let fields = {
          country: {
            type: "select",
            options: this.result["additional"]["rattuxCountries"],
            name: "Country"
          },
          operator: {
            type: "select",
            asyncOptions: function(templateContent) {
              console.log("option Called", templateContent);
              // return [0, 1];
              let value = templateContent.fields.country.value;
              //let caller = this;
              return new Promise(resolve => {
                let options = {
                  function: "getRatAllOperator",
                  ratCountryval: value
                };
                ////console.log(options);
                requests.frameworkAxiosRequest({
                  method: 'post',
                  url: 'serve.php?f=testing&f2=configureAndRunTests',
                  data: options,
                })
                    .then(function(response) {
                    // //console.log(response);
                    resolve(response.data.result.json.operatorList);
                  })
                  .catch(function() {
                    //handle error
                  });
              });
            },
            name: "Operator"
          },
          rattype: {
            type: "select",
            asyncOptions: function(templateContent) {
              //console.log("option Called", templateContent);
              // return [0, 1];
              let countryVal = templateContent.fields.country.value;
              let operatorVal = templateContent.fields.operator.value;
              //let caller = this;
              return new Promise(resolve => {
                let options = {
                  function: "getRatType",
                  ratOperatorval: operatorVal,
                  ratCountryval: countryVal
                };
                ////console.log(options);
                requests.frameworkAxiosRequest({
                  method: 'post',
                  url: 'serve.php?f=testing&f2=configureAndRunTests',
                  data: options,
                })
                    .then(function(response) {
                    // //console.log(response);
                    resolve(response.data.result.json.ratTypeList);
                  })
                  .catch(function() {
                    //handle error
                  });
              });
            },
            name: "RAT Type"
          },
          remove: {
            inputFieldFormatter: "trash",
            colAttrs: { style: "max-width: 23px;min-width: 35px;", class: "mt-1" }
          }
        };
        return fields;
      },
      pairArray: {
        get() {
          // console.log(
          //   "pairArray getter is called",
          //   JSON.stringify(this.value),
          //   this.value
          // );
          return this.value;
        }
        // set(newValue) {
        //     console.log("new value",newValue);
        // }
      }
    },
    methods: {
      checkInput(value) {
        console.log(value);

        this.$emit("input", value);
      },
      checkUpdate(index, field, value) {
        console.log("checkUpdate", field, value);
        if (field == "country") {
          console.log("checkUpdate");
          this.$set(this.rerunsObject, index, ["operator"]);
          console.log(JSON.stringify(this.rerunsObject));
          this.$nextTick(() => {
            this.$delete(this.rerunsObject, index);
          });
        } else if (field == "operator") {
          console.log("checkUpdate");
          this.$set(this.rerunsObject, index, ["rattype"]);
          //console.log(JSON.stringify(this.rerunsObject));
          this.$nextTick(() => {
            this.$delete(this.rerunsObject, index);
          });
        }
      }
    }
  };

  // var counter = 0;
  // let visibility;
  // var networks = givenValue;
  // var rattuxOperator = 0;
  // var rattuxType = 0;
  // //var $additionalFields=$("<tbody></tbody>").appendTo($insertingTable);
  // var headerStyle = "style='min-width:105px;max-width:105px;'";
  // if (networks.length > 0) {
  //   //Existing Network
  //   $.each(networks, function(i, object) {
  //     counter++;
  //     if (counter == 1) {
  //       visibility = "hidden";
  //     } else {
  //       visibility = "visible";
  //     }
  //     let $rattuxCountriesRow = getNetworkLine(
  //       $attributesContainer,
  //       window.rattuxCountries,
  //       object,
  //       visibility
  //     );
  //     constructOperatorList(
  //       $rattuxCountriesRow.find(".rattuxCountries"),
  //       object.operator,
  //       object.rattype
  //     );
  //   });
  // } else {
  //   let valueObject = {
  //     country: "",
  //     operator: 0,
  //     rattype: 0
  //   };
  //   let visibility = "hidden";
  //   getNetworkLine(
  //     $attributesContainer,
  //     window.rattuxCountries,
  //     valueObject,
  //     visibility
  //   );
  // }
  // $(
  //   "<div class='plusNetwork'><div class='attrName rattuxCommandName inlineDiv'></div><div class='inlineDiv'>" +
  //     addNetwork +
  //     "</div></div>"
  // ).appendTo($attributesContainer);
</script>